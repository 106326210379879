body {
  background-color: #EFEFEF;
  font-family: 'Roboto'
}

.main {
  text-align: center;
}

.my-card {
  margin: auto;
}

.avatar {
  border-radius: 100px;
  width: 100px;
  height: 100px;
  // border: 3px solid #999;
}

#user-name {
  text-transform: uppercase;
  font-weight: bold;
}

#success-section{
  display: flex;
  flex-flow: column nowrap;
}

.payment-request-button{
  margin-top: 10px
}

#success-check {
  height: 50px;
}

#success-message {
  margin-top: 8px;
}
